.rock-convert-banner {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  display: block;
}

.powered-by-rock-convert {
  clear: both;
  width: 100%;
  text-align: right;
  margin-top: 10px;
  font-size: 10px;
  display: block;
}

.powered-by-rock-convert a {
  color: #0052cc;
}

.rock-convert-download {
  text-align: center;
  color: #1f1e1d;
  margin: 20px 0;
}

.rock-convert-download-container {
  text-align: center;
  background-color: #f5f4ef;
  border-radius: 4px;
  padding: 30px;
}

.rock-convert-download-container input[type="email"] {
  letter-spacing: .31px;
  color: #979797;
  width: 80%;
  background-color: #fff;
  border: 0;
  border-radius: 20px;
  flex-grow: 2;
  margin: 0 auto 10px;
  padding: 10px 18px;
  font-size: 14px;
  line-height: 100%;
}

.rock-convert-download-container-title {
  color: #1f1e1d;
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

.rock-convert-download-container input[type="email"]:focus {
  outline: none;
}

.rock-convert-download-container input[type="submit"] {
  color: #fff;
  cursor: pointer;
  background-color: #6083a9;
  border: 0;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 13px;
  font-weight: 500;
  margin: 0 auto !important;
}

.rock-convert-download-container-form {
  text-align: center;
}

.rock-convert-download-container-form-help {
  color: #979797;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  display: block;
}

.rock-convert-subscribe-form {
  letter-spacing: .31px;
  color: #979797;
  width: 100%;
  text-align: center;
  border: 0;
  border-radius: 10px;
  margin: 0 auto 10px;
  padding: 35px 28px;
  font-size: 14px;
  line-height: 100%;
}

#single-sidebar .rock-convert-subscribe-form .g-recaptcha {
  transform-origin: 0 0;
  transform: scale(.72);
  padding-bottom: 0 !important;
}

@media (max-width: 1024px) {
  #single-sidebar .rock-convert-subscribe-form .g-recaptcha {
    transform: scale(.58);
  }
}

@media (max-width: 800px) {
  #single-sidebar .rock-convert-subscribe-form .g-recaptcha {
    transform: scale(1);
  }
}

@media (max-width: 415px) {
  #single-sidebar .rock-convert-subscribe-form .g-recaptcha {
    transform: scale(.95);
  }
}

@media (max-width: 375px) {
  #single-sidebar .rock-convert-subscribe-form .g-recaptcha {
    transform: scale(.82);
  }
}

@media (max-width: 320px) {
  #single-sidebar .rock-convert-subscribe-form .g-recaptcha {
    transform: scale(.65);
  }
}

.rock-convert-subscribe-form .g-recaptcha {
  transform-origin: 0 0;
  transform: scale(.98);
}

@media (max-width: 1024px) {
  .rock-convert-subscribe-form .g-recaptcha {
    transform: scale(.78);
  }
}

@media (max-width: 800px) {
  .rock-convert-subscribe-form .g-recaptcha {
    transform: scale(1);
  }
}

@media (max-width: 375px) {
  .rock-convert-subscribe-form .g-recaptcha {
    transform: scale(.92);
  }
}

@media (max-width: 320px) {
  .rock-convert-subscribe-form .g-recaptcha {
    transform: scale(.74);
  }
}

.rock-convert-subscribe-form input[type="submit"] {
  color: #fff;
  width: 100%;
  cursor: pointer;
  border: 0;
  border-radius: 30px;
  margin: 10px 0;
  padding: 10px 30px;
  font-size: 13px;
  font-weight: 500;
}

.rock-convert-subscribe-form input[type="submit"]:active, .rock-convert-subscribe-form input[type="submit"]:focus {
  outline: none;
}

.rock-convert-subscribe-form-title {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}

.rock-convert-subscribe-form-hint {
  text-align: center;
  font-size: 12px;
  display: block;
}

.rock-convert-subscribe-form-email {
  letter-spacing: .31px;
  color: #979797;
  width: 100%;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 20px;
  padding: 10px 18px;
  font-size: 14px;
  line-height: 100%;
}

.rock-convert-subscribe-form-email:focus {
  border: 0;
  outline: none;
}

.rock-convert-alert-success, .rock-convert-alert-error {
  border-radius: 10px;
  font-size: 13px;
  text-align: center !important;
  margin-bottom: 10px !important;
  padding: 10px !important;
}

.rock-convert-alert-success strong, .rock-convert-alert-error strong {
  font-size: 14px;
}

.rock-convert-alert-error {
  color: #690202 !important;
  background: #fdf1f1 !important;
  border: 1px solid #690202 !important;
}

.rock-convert-alert-success {
  color: #18513a !important;
  background: #e4fbec !important;
  border: 1px solid #40c075b3 !important;
}

@media (max-width: 575.98px) {
  .rock-convert-download-container input[type="email"], .rock-convert-download-container input[type="submit"] {
    width: 100%;
  }
}

.rconvert_announcement_bar {
  width: 100%;
  color: #1f1e1d;
  height: 50px;
  z-index: 1030;
  background-color: #f5f4ef;
  -webkit-transition-property: background;
  -webkit-transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  box-shadow: 0 3px 6px #3535344d;
}

.rconvert_announcement_bar--top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.rconvert_announcement_bar--bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.rconvert_announcement_bar__container {
  height: 48px;
  color: #1f1e1d;
  justify-content: center;
  align-items: center;
  font-family: Verdana, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  display: flex;
}

.rconvert_announcement_bar__container__cta {
  text-transform: uppercase;
  color: #fff;
  float: left;
  background: #263473;
  border-radius: 30px;
  margin: 0 0 0 20px;
  padding: 4px 20px;
  font-size: 12px;
  font-weight: 600;
  transition: all .8s;
  display: inline-block;
  text-decoration: none !important;
}

.rconvert_announcement_bar__container__cta:hover, .rconvert_announcement_bar__container__cta:active, .rconvert_announcement_bar__container__cta:visited {
  color: #fff;
}

.rock-convert-widget-cta {
  height: 250px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.rock-convert-widget-cta > a {
  z-index: 1000;
  height: 100%;
  align-items: center;
  font-family: inherit;
  text-decoration: none;
  display: flex;
  position: relative;
}

.rock-convert-widget-cta > a:hover {
  text-decoration: none;
}

.rock-convert-widget-cta a span {
  text-transform: uppercase;
  letter-spacing: 0;
  padding: 0 15%;
  font-family: inherit;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  display: block;
  position: relative;
}

.rock-convert-widget-cta a span:before, .rock-convert-widget-cta a span:after {
  content: "";
  width: 100px;
  height: 5px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
}

.rock-convert-widget-cta a span:before {
  top: -20px;
}

.rock-convert-widget-cta a span:after {
  bottom: -20px;
}

body.rc_announcement_bar--top {
  padding-top: 50px;
}

body.rc_announcement_bar--bottom {
  padding-bottom: 50px;
}

.rc-mb-1 {
  margin-bottom: 1rem;
}

.rc-mb-2 {
  margin-bottom: 2rem;
}

.rc-mb-3 {
  margin-bottom: 3rem;
}

.rc-mb-4 {
  margin-bottom: 4rem;
}

.rc-mb-5 {
  margin-bottom: 5rem;
}

@media (max-width: 575.98px) {
  .rconvert_announcement_bar, .rconvert_announcement_bar__container {
    height: 120px;
  }

  body.rc_announcement_bar {
    padding-top: 120px;
  }

  .rconvert_announcement_bar__container {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    font-size: 14px;
    line-height: 18px;
  }

  .rconvert_announcement_bar__container__cta {
    margin: 10px 0 0;
  }
}

.g-recaptcha {
  width: 1px;
  padding: 20px 0;
  display: block;
}

.convert-popup {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.convert-popup-box {
  color: #fff;
  width: 700px;
  max-height: 483px;
  background-color: #333;
  border-radius: 10px;
  align-items: center;
  font-family: Open Sans Condensed, sans-serif;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.convert-popup-box h1 {
  text-align: center;
  font-size: 35px;
}

.convert-popup-box h2 {
  font-size: 25px;
  font-weight: 700;
}

.convert-popup-box-ni {
  color: #fff;
  background-color: #333;
  border-radius: 10px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.convert-popup-box-ni p {
  margin: 0 20px;
}

@media (max-width: 405px) {
  .convert-popup-box-ni {
    width: 100% !important;
  }
}

@media (max-height: 654px) {
  .convert-popup-box-ni {
    width: 100% !important;
  }
}

.convert-popup-content {
  width: 100%;
  color: #000;
  overflow-wrap: break-word;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
  display: flex;
}

.convert-popup-email {
  width: 95% !important;
  border-radius: 5px !important;
  margin: 0 20px !important;
  padding: 7px 0 !important;
}

.convert-popup-btn {
  color: #fff;
  background-color: #a46497;
  width: 95% !important;
  border: none !important;
  border-radius: 5px !important;
  margin: 10px 20px 0 !important;
  padding: 6px !important;
  font-weight: 900 !important;
}

.convert-popup-box p {
  margin: 0 0 0 20px;
}

.convert-popup-content h2 {
  width: 99%;
  align-items: baseline;
  margin: 20px 0 0 20px;
  display: flex;
}

.convert-popup-close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .7;
  text-align: center;
  cursor: pointer;
  width: 20px;
  height: 15px;
  background-color: #fff;
  border-radius: 20px;
  margin: 10px 0 0 -30px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 100%;
}

.convert-popup-close hover {
  color: #fff;
}

#rock_convert_popup_image {
  width: 300px;
  height: 100%;
  border-radius: 10px 0 0 10px;
}

@media (max-width: 735px) {
  .convert-popup-box {
    width: 90%;
    max-height: 590px;
    flex-direction: column;
  }

  #rock_convert_popup_image {
    width: 100%;
    height: 45%;
    border-radius: 10px 10px 0 0;
  }

  .convert-popup-content {
    align-items: center;
    padding: 10px 0 0;
  }

  .convert-popup-content form {
    margin: 10px 0 20px !important;
  }

  .convert-popup-box p, h2 {
    margin: 0 15px;
    width: 90% !important;
  }

  .convert-popup-email {
    width: 93% !important;
    margin: 0 15px !important;
  }

  .convert-popup-btn {
    width: 93% !important;
    margin: 10px 20px 0 15px !important;
  }

  .convert-popup-content h2 {
    margin: 20px 0 0;
  }
}

@media (max-width: 608px) {
  #rock_convert_popup_image {
    height: 35%;
  }
}

@media (max-width: 508px) {
  #rock_convert_popup_image {
    height: 30%;
    border-radius: 10px 10px 0 0;
  }
}

@media (max-width: 456px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size: 27px;
  }
}

@media (max-width: 434px) {
  .convert-popup-content h2 {
    font-size: 26px;
  }
}

@media (max-width: 423px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size: 25px;
  }
}

@media (max-width: 407px) {
  .convert-popup-content h2 {
    font-size: 24px;
  }
}

@media (max-width: 383px) {
  .convert-popup-content h2 {
    font-size: 23px;
  }
}

@media (max-width: 374px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size: 22px;
  }

  .convert-popup-box p {
    font-size: 15px;
  }
}

@media (max-width: 351px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size: 20px;
  }
}

@media (max-width: 319px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size: 19px;
  }
}

@media (max-width: 303px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size: 18px;
  }
}

@media (max-width: 287px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size: 17px;
  }
}

@media (max-height: 792px) {
  .convert-popup-box {
    max-height: 557px;
  }

  .convert-popup-box p {
    font-size: 11px;
  }
}

@media (max-width: 800px) {
  .convert-popup-box {
    height: 100%;
  }
}



/*# sourceMappingURL=frontend.css.map */
