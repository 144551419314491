.rock-convert-banner {
    width: 100%;
    display: block;
    margin: 10px 0;
    text-align: center;
}

.powered-by-rock-convert {
    font-size: 10px;
    clear: both;
    display: block;
    width: 100%;
    text-align: right;
    margin-top: 10px;
}

.powered-by-rock-convert a {
    color: #0052CC;
}

.rock-convert-download {
    text-align: center;
    color: #1f1e1d;
    margin: 20px 0;
}

.rock-convert-download-container {
    background-color: #f5f4ef;
    border-radius: 4px;
    padding: 30px;
    text-align: center;
}

.rock-convert-download-container input[type="email"] {
    background-color: #fff;
    border-radius: 20px;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: .31px;
    color: #979797;
    padding: 10px 18px;
    border: 0;
    -webkit-box-flex: 2;
    flex-grow: 2;
    width: 80%;
    margin: 0 auto 10px;
}

.rock-convert-download-container-title {
    font-size: 16px;
    line-height: 20px;
    color: rgb(31, 30, 29);
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
}

.rock-convert-download-container input[type="email"]:focus {
    outline: none;
}

.rock-convert-download-container input[type="submit"] {
    background-color: #6083A9;
    color: #fff;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-size: 13px;
    border: 0;
    padding: 10px 30px;
    cursor: pointer;
    font-weight: 500;
    margin: 0 auto !important;
}

.rock-convert-download-container-form {
    text-align: center;
}

.rock-convert-download-container-form-help {
    display: block;
    color: #979797;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
}

/**
Subscribe form styles
 */
.rock-convert-subscribe-form {
    border-radius: 10px;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: .31px;
    color: #979797;
    padding: 35px 28px;
    border: 0;
    width: 100%;
    margin: 0 auto 10px;
    text-align: center;
}

#single-sidebar .rock-convert-subscribe-form .g-recaptcha{
    transform:scale(0.72);
    -webkit-transform:scale(0.72);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    padding-bottom: 0 !important;
}

@media (max-width: 1024px) {
    #single-sidebar .rock-convert-subscribe-form .g-recaptcha{
        transform:scale(0.58);
        -webkit-transform:scale(0.58);
    }
}

@media (max-width: 800px) {
    #single-sidebar .rock-convert-subscribe-form .g-recaptcha{
        transform:scale(1);
        -webkit-transform:scale(1);
    }
}

@media (max-width: 415px) {
    #single-sidebar .rock-convert-subscribe-form .g-recaptcha{
        transform:scale(0.95);
        -webkit-transform:scale(0.95);
    }
}

@media (max-width: 375px) {
    #single-sidebar .rock-convert-subscribe-form .g-recaptcha{
        transform:scale(0.82);
        -webkit-transform:scale(0.82);
    }
}

@media (max-width: 320px) {
    #single-sidebar .rock-convert-subscribe-form .g-recaptcha{
        transform:scale(0.65);
        -webkit-transform:scale(0.65);
    }
}

.rock-convert-subscribe-form .g-recaptcha{
    transform:scale(0.98);
    -webkit-transform:scale(0.98);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
}

@media (max-width: 1024px) {
    .rock-convert-subscribe-form .g-recaptcha{
        transform:scale(0.78);
        -webkit-transform:scale(0.78);
    }
}

@media (max-width: 800px) {
    .rock-convert-subscribe-form .g-recaptcha{
        transform:scale(1);
        -webkit-transform:scale(1);
    }
}

@media (max-width: 375px) {
    .rock-convert-subscribe-form .g-recaptcha{
        transform:scale(0.92);
        -webkit-transform:scale(0.92);
    }
}

@media (max-width: 320px) {
    .rock-convert-subscribe-form .g-recaptcha{
        transform:scale(0.74);
        -webkit-transform:scale(0.74);
    }
}

.rock-convert-subscribe-form input[type="submit"] {
    color: #fff;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-size: 13px;
    border: 0;
    padding: 10px 30px;
    margin: 10px 0;
    width: 100%;
    cursor: pointer;
    font-weight: 500;
}

.rock-convert-subscribe-form input[type="submit"]:active,
.rock-convert-subscribe-form input[type="submit"]:focus {
    outline: none;
}

.rock-convert-subscribe-form-title {
    font-size: 14px;
    margin: 10px 0;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
}

.rock-convert-subscribe-form-hint {
    font-size: 12px;
    text-align: center;
    display: block;
}

.rock-convert-subscribe-form-email {
    background-color: #fff;
    border-radius: 20px;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: .31px;
    color: #979797;
    padding: 10px 18px;
    border: 1px solid #EEE;
    width: 100%;
}

.rock-convert-subscribe-form-email:focus {
    border: 0;
    outline: none;
}

.rock-convert-alert-success,
.rock-convert-alert-error {
    padding: 10px !important;
    text-align: center !important;
    margin-bottom: 10px !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 13px;
}

.rock-convert-alert-success strong,
.rock-convert-alert-error strong {
    font-size: 14px;
}

.rock-convert-alert-error {
    background: #fdf1f1 !important;
    border: 1px solid #690202 !important;
    color: #690202 !important;
}

.rock-convert-alert-success {
    background: #E4FBEC !important;
    border: 1px solid rgba(64, 192, 117, .7) !important;
    color: #18513A !important;
}

@media (max-width: 575.98px) {
    .rock-convert-download-container input[type="email"], .rock-convert-download-container input[type="submit"] {
        width: 100%;
    }
}

.rconvert_announcement_bar {
    width: 100%;
    -webkit-box-shadow: 0 3px 6px rgba(53, 53, 52, .3);
    box-shadow: 0 3px 6px rgba(53, 53, 52, .3);
    background-color: #f5f4ef;
    color: rgba(31, 30, 29, 1);
    height: 50px;
    z-index: 1030;
    -webkit-transition-property: background;
    -webkit-transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
}

.rconvert_announcement_bar--top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
}

.rconvert_announcement_bar--bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
}

.rconvert_announcement_bar__container {
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: rgba(31, 30, 29, 1);
    font-size: 16px;
    font-family: Verdana, sans-serif;
    font-weight: bold;
    line-height: 22px;
}

.rconvert_announcement_bar__container__cta {
    background: #263473;
    border-radius: 30px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none !important;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    float: left;
    margin: 0 0 0 20px;
    -webkit-transition: all .8s ease;
    transition: all .8s ease;
    padding: 4px 20px;
}

.rconvert_announcement_bar__container__cta:hover,
.rconvert_announcement_bar__container__cta:active,
.rconvert_announcement_bar__container__cta:visited {
    color: #FFF;
}

.rock-convert-widget-cta {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 100%;
    text-align: center;
}

.rock-convert-widget-cta > a {
    z-index: 1000;
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    font-family: inherit;
    text-decoration: none;
}

.rock-convert-widget-cta > a:hover {
    text-decoration: none;
}

.rock-convert-widget-cta a span {
    position: relative;
    display: block;
    padding: 0 15%;
    font-family: inherit;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0;
}

.rock-convert-widget-cta a span::before, .rock-convert-widget-cta a span::after {
    content: "";
    width: 100px;
    height: 5px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.rock-convert-widget-cta a span::before {
    top: -20px;
}

.rock-convert-widget-cta a span::after {
    bottom: -20px;
}

body.rc_announcement_bar--top {
    padding-top: 50px;
}
body.rc_announcement_bar--bottom {
    padding-bottom: 50px;
}

.rc-mb-1{margin-bottom: 1rem;}
.rc-mb-2{margin-bottom: 2rem;}
.rc-mb-3{margin-bottom: 3rem;}
.rc-mb-4{margin-bottom: 4rem;}
.rc-mb-5{margin-bottom: 5rem;}

@media (max-width: 575.98px) {
    .rconvert_announcement_bar,
    .rconvert_announcement_bar__container {
        height: 120px;
    }

    body.rc_announcement_bar {
        padding-top: 120px;
    }

    .rconvert_announcement_bar__container {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        padding: 0 20px;
    }

    .rconvert_announcement_bar__container__cta {
        margin: 10px 0 0 0;

    }
}

/**
Captcha styles
 */

  .g-recaptcha{
    padding: 20px 0;
    display: block;
    width: 1px;
  }

  .convert-popup {
    display: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .convert-popup-box {
    font-family: 'Open Sans Condensed', sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #333;
    color: #fff;
    display: flex;
    width: 700px;
    border-radius: 10px;
    align-items: center;
    max-height: 483px;
  }

  .convert-popup-box h1 {
    text-align: center;
    font-size: 35px;
  }

  .convert-popup-box h2 {
    font-weight: 700;
    font-size: 25px;
  }

  .convert-popup-box-ni {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #333;
    color: #fff;
    display: flex;
    border-radius: 10px;
    align-items: center;
  }

  .convert-popup-box-ni p {
      margin: 0 20px;
  }

  @media (max-width: 405px){
    .convert-popup-box-ni {
      width: 100% !important;
    }
  }

  @media (max-height: 654px) {
    .convert-popup-box-ni {
        width: 100% !important;
      }
  }

  .convert-popup-content{
    display: flex;
    align-items: baseline;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    color: black;
    overflow-wrap: break-word;
  }

  .convert-popup-email{
    margin: 0 20px !important;
    border-radius: 5px !important;
    padding: 7px 0 !important;
    width: 95% !important;
  }

  .convert-popup-btn{
    width: 95% !important;
    border: none !important;
    margin: 10px 20px 0 20px !important;
    border-radius: 5px !important;
    background-color: #a46497;
    color: white;
    font-weight: 900 !important;
    padding: 6px !important;
  }

  .convert-popup-box p{
    margin: 0 0 0 20px;
  }

  .convert-popup-content h2{
      width: 99%;
      display: flex;
      align-items: baseline;
      margin: 20px 0 0 20px;
  }

  .convert-popup-close {
    float: right;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .7;
    position: absolute;
    left: 100%;
    top: 0;
    margin: 10px 0 0 -30px;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    width: 20px;
    height: 15px;
    background-color: white;
    border-radius: 20px;
  }

  .convert-popup-close hover {
    color: #fff;
  }

  #rock_convert_popup_image{
    width: 300px;
    height: 100%;
    border-radius: 10px 0 0 10px;
  }

  @media (max-width: 735px) {
    .convert-popup-box{
        flex-direction: column;
        width: 90%;
        max-height: 590px;
    }

    #rock_convert_popup_image{
        width: 100%;
        height: 45%;
        border-radius: 10px 10px 0 0;
    }

    .convert-popup-content {
        padding: 10px 0 0 0;
        align-items: center;
    }

    .convert-popup-content form {
        margin: 10px 0 20px 0 !important;
    }

    .convert-popup-box p, h2 {
        margin: 0 15px;
        width: 90% !important;
    }

    .convert-popup-email{
        margin: 0 15px !important;
        width: 93% !important;
    }

    .convert-popup-btn{
        margin: 10px 20px 0 15px !important;
        width: 93% !important;
    }

    .convert-popup-content h2 {
        margin: 20px 0 0;
    }
  }

  @media (max-width: 608px) {
    #rock_convert_popup_image {
        height: 35%;
    }
  }

@media (max-width: 508px) {
    #rock_convert_popup_image {
        height: 30%;
        border-radius: 10px 10px 0 0;
    }
}
@media (max-width: 456px) {
    .convert-popup-content h2 {
        margin: 20px 0 0;
        font-size:27px;
    }
}
@media (max-width: 434px) {
    .convert-popup-content h2 {
        font-size:26px;
    }
}
@media (max-width: 423px) {
    .convert-popup-content h2 {
        margin: 20px 0 0;
        font-size:25px;
    }
}
@media (max-width: 407px) {
    .convert-popup-content h2 {
        font-size:24px;
    }
}
@media (max-width: 383px) {
    .convert-popup-content h2 {
        font-size:23px;
    }
}
@media (max-width: 374px) {
    .convert-popup-content h2 {
        margin: 20px 0 0;
        font-size:22px;
    }
    .convert-popup-box p {
        font-size: 15px;
    }
}

@media (max-width: 351px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size:21px;
  }
}

@media (max-width: 351px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size:20px;
  }
}

@media (max-width: 319px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size:19px;
  }
}

@media (max-width: 303px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size:18px;
  }
}

@media (max-width: 287px) {
  .convert-popup-content h2 {
    margin: 20px 0 0;
    font-size:17px;
  }
}

@media (max-height: 792px){
    .convert-popup-box {
        max-height: 557px;
    }

    .convert-popup-box p {
        font-size: 11px;
    }
}

@media (max-width: 800px) {
    .convert-popup-box{
        height: 100%;
    }
}
